import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "./views/Landing.js";
import Contact from "components/Contact.js";
import About from "./components/About.js";
import Blog from "components/Blog.js";
import Service from "components/Service.js";
import Fluidity from "components/Fluidity.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/landing' component={Landing} />
      <Route path="/contact" component={Contact} />
      <Route path="/about" component={About} />
      <Route path="/blog" component={Blog} />
      <Route path="/service" component={Service} />
      <Route path="/fluidity" component={Fluidity} />

      <Redirect from='/' to='/landing' />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
