import React from 'react'
import Profile from "components/PService.js"
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import Serviceoffer from "components/ServiceOffer";


function Service() {
  return (
    <>
<Navbar transparent />

<Profile />
<Serviceoffer />
<Footer />
  </>
)
}

export default Service