import React from 'react'
import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import HBanner from "components/HBanner.js"
import Profile from './Profile';
import imagine from "../assets/img/imagine.jpeg";
import Logo from "../components/Logo.js";


function About(props) {
  return (
<>
<Navbar transparent />
<HBanner/>

    <section className="relative py-20">
    <div
      className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
      style={{ height: "80px" }}
    >
      <svg
        className="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-white fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div className="container mx-auto px-4">
      <div className="items-center flex flex-wrap">
        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
          <img
            alt="..."
            className="max-w-full rounded-lg shadow-lg"
            src={imagine}
          />
        </div>
        <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
          <div className="md:pr-12">
            <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
              <i className="fas fa-rocket text-xl"></i>
            </div>
            <h3 className="text-3xl font-semibold">
              A growing company
            </h3>
            <p className="mt-4 text-lg leading-relaxed text-gray-600">
            D-sides is a global collaborative of experts and specialists working with builders and leaders to create  spaces for all. 

We provide expertise in evaluating progress towards meaningful impact. <br/><br/>
            Our technical expertise, innovative thinking and sound guidance help clients mitigate the risks of complex challenges and crises, with human-centric evaluations that build resilient leaders, contributors, and environments.<br/><br/>

We work with organizations to build towards greater inclusion, accessibility and wellness with a broad range of clients worldwide, including government agencies, policy makers, post-secondary and healthcare institutions, community organizations, private corporations and many others. 

            </p>
            <ul className="list-none mt-6">
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                      <i className="fas fa-fingerprint"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600">
                      Carefully crafted discussion ideation 
                    </h4>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                      <i className="fab fa-html5"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600">Human-centred approach</h4>
                  </div>
                </div>
              </li>
              <li className="py-2">
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                      <i className="far fa-paper-plane"></i>
                    </span>
                  </div>
                  <div>
                    <h4 className="text-gray-600">Dynamic components</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Profile />
  <h1 className="text-center text-2xl font-bold my-8">We have worked with…</h1>

  <Logo />
  <Footer />

  </>

);
}

export default About