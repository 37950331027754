import React from 'react'
import neil from "../assets/img/photo.jpg";
import hema from "../assets/img/4Mcopy.jpg";

function Profile() {
  return (
    <>
<section className="bg-gray-100">
    <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
    <div className="items-center flex flex-wrap">
    <div className="w-full md:w-6/12 ml-auto mr-auto px-2">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Hema Vyas </h2>
                <p className="mt-4 text-gray-600 text-lg">
                A MSc (she/her) is regularly invited to collaborate, present and speak to her range of expertise, including effective partnership building; key performance measures & evaluation; and strengthening democratic participation. This range reflects her experience in strategic planning and measuring progress to success in non-profit, corporate and government sectors.

Hema is a former city council candidate. While losing isn’t easy, she reflected on her learnings to establish a program preparing other women to run for office. The program resulted in 10 of 15 participants running, with 3 elected to office.

She has won several evaluation and policy-making awards as a result of chairing evaluation working groups for education, housing and labour market initiatives. Hema has led the design and implementation of mixed method (quantitative and qualitative) frameworks with the end goal of building consensus on report results for system improvements.

Hema has a track record of building effective partnerships to collaborate towards shared goals.  She has written a handbook for partnership building, and presents best practices in partnership building. 

</p>
                <div className="mt-8">
                </div>
            </div>
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img src={hema} 
            alt="About Us Image" 
            className="object-cover rounded-lg shadow-md" />
            </div>
        </div>
    </div>
</section>

<section className="bg-gray-100">
    <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
    <div className="items-center flex flex-wrap">
    <div className="w-full md:w-4/12 ml-auto mr-auto px-4">

            <img src={neil} 
            alt="Hema Vyas" 
            className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full" />
            </div>
    <div className="w-full md:w-6/12 ml-auto mr-auto ">
                <p className="mt-4 text-gray-600 text-lg"> 
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">  O'neil Edwards </h2>

A PhD, MBA (he/him) is an award-winning education professional who has two decades of senior leadership experience in education globally for non-profits, secondary schools and post-secondary institutions.  During his over- twenty dedicated years of post-secondary leadership, O’neil has been instrumental in guiding planning processes to support the development of innovative programs and services that promote student retention, engagement, and success, and has led interdisciplinary teams in addressing emerging priorities. He has led collaborative work on implementing access departments, international student foundation programs and strategies at the post-secondary level in Canada. 
</p>
                <div className="mt-8">
                </div>
            </div>
          
        </div>
    </div>
</section>
</>
  )
}

export default Profile