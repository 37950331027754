import React from "react";
import  logo from "../assets/img/dsideslogo.jpg"


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full bg-white"
          : "relative bg-white shadow-lg") +
        " flex flex-wrap items-center justify-between px-2 py-2 "
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={
              (props.transparent ? "text-white" : "text-gray-800") +
              " text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            }
            href="/landing"
          >
              <img
                className="w-auto h-12 sm:h-24 px-2"
                src={logo}
                width='80px' 
              />
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text" : "text-gray-800") +
                " fas fa-bars"
              }
            ></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none mr-auto">
            <li className="flex items-center">
     {/*          <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-7 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/landing"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text-black-300 text-gray-500  text-base"
                      : "text-gray-500 text-2xl") +
                    " far fa-file-alt text-lg leading-lg mr-2"
                  }
                />{" "}
                Dsides
              </a> */}
            </li>
          </ul>  <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
          {/*     <a
                className={
                  (props.transparent
                    ? "lg:text-black lg:hover:text-gray-300 text-gray-800  text-base"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/blog"
              >
                Blog
              </a> */}
              <a
                className={
                  (props.transparent
                    ? "lg:text-black lg:hover:text-gray-300 text-gray-800  text-base"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/about"
              >
                About Us
              </a>

              <a
                className={
                  (props.transparent
                    ? "lg:text-black lg:hover:text-gray-300 text-gray-800 text-base"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/service"
              >
                Our Services
              </a>

              <a
                className={
                  (props.transparent
                    ? "lg:text-black lg:hover:text-gray-300 text-gray-800 text-base"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/fluidity"
              >
                Fluidity Matrix
              </a>

              <a
                className={
                  (props.transparent
                    ? "lg:text-black lg:hover:text-gray-300 text-gray-800 text-base"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/contact"
              >
                Contact
              </a>
            </li></ul>
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-black lg:hover:text-gray-300 text-gray-800 text-base"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="https://www.linkedin.com/company/d-sides-leadership"
              >
                <i
                  className={
                    (props.transparent
                      ? "lg:text text-gray-500"
                      : "text-gray-500") +
                    " fab fa-linkedin text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">linkedin</span>
              </a>
            </li>

        

        

            <li className="flex items-center">
              <a href="/contact"><button
                className={
                  (props.transparent
                    ? "bg-pink-500 text-gray-800 active:bg-pink-100"
                    : "bg-pink-500 text-white active:bg-pink-600") +
                  " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                }
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                <i className="fas fa-envelope"></i> Contact   
              </button></a>
            </li>
          </ul>
        </div>
      </div>
      <br/>
    </nav>
  );
}
