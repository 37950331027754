import React from 'react'

function ServiceOffer() {
  return (
    <section className="pb-20 relative block bg-gray-900">
    <div
      className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
      style={{ height: "80px" }}
    >
      <svg
        className="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-gray-900 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>

    <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
      <div className="flex flex-wrap text-center justify-center">
        <div className="w-full lg:w-6/12 px-4">
          <h2 className="text-4xl font-semibold text-white">
          We leverage research

</h2>
          <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
          We are fearless in evaluating progress to goals, and prioritize thoughtful, innovative solutions that promote equity, wellbeing, and participation.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap mt-12 justify-center">
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
          <i className="fas fa-user-friends text-xl"></i>
          </div>
          <h6 className="text-xl mt-5 font-semibold text-white">
          Empathy and Reasoning
          </h6>
          <p className="mt-2 mb-4 text-gray-500">
          With an approach that examines complex problems from a human perspective. We evaluate what's working and what's not.
          </p>
        </div>
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
            <i className="fas fa-poll text-xl"></i>
          </div>
          <h5 className="text-xl mt-5 font-semibold text-white">
          Creativity and Ideation
          </h5>
          <p className="mt-2 mb-4 text-gray-500">
          To explore, visualize and validate evidence-based human-centred design and strategies.
          </p>
        </div>
        <div className="w-full lg:w-3/12 px-4 text-center">
          <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
            <i className="fas fa-lightbulb text-xl"></i>
          </div>
          <h5 className="text-xl mt-5 font-semibold text-white">
          Analysis and Critique 
          </h5>
          <p className="mt-2 mb-4 text-gray-500">
          To leverage research and define a problem space to identify needs. We recognize the importance of adapting to changing contexts.                 </p>
        </div>
      </div>
    </div>
  </section>

  )
}

export default ServiceOffer