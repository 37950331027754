import React from "react";

import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Logo from "../components/Logo.js";
import Video from "../components/Video.js";
import fun from "../assets/img/wood.jpg";
import ScrollTextBackground from "../components/ScrollTextBackground.js";

export default function Landing(props) {
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "90vh"
            }}>

          <Video fun />
          

          {/* <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div> */}
        </div>
        <section>
        <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center"><br/><br/>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Fluidity Matrix</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    Building the teams and trust that lead to efficient, superior products for clients.
                    </p>
                  
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center"><br/><br/><br/><br/>

                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                    The Human-Centred Organization
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Develop solutions in all steps of the problem-solving process.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center"><br/><br/><br/>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Our Work
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                    Measure progress to goals, shape
solutions, and evaluate as we move forward
                    </p>
                  </div>
                </div>
              </div>
         
            </div>


        </section>
        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
           
            <div className="flex flex-wrap items-center mt-32">
              
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Working with us is fun
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                We are fearless in evaluating progress to goals, and prioritize thoughtful, innovative solutions that promote equity, well-being, and results.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  
                </p>
                <a href="/contact"><button
                className={
                  (props.transparent
                    ? "bg-white text-gray-800 active:bg-blue-100"
                    : "bg-pink-500 text-white active:bg-blue-600") +
                  " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                }
                type="button"
                style={{ transition: "all .15s ease" }}
              >
<i class='fas fa-phone-alt'></i>  
&nbsp; Contact Us   
              </button></a>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto"><br/><br/><br/>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    // src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    src={fun}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-pink-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                    Creativity and Ideation in Consulting
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                    Creativity and Ideation to explore, visualize and validate evidence-based human-centred design and strategies.
                    </p>
                  </blockquote>
                </div>
              </div>

            </div>
            
          </div>
        </section>
        <h1 className="text-center text-2xl font-bold my-8">We have worked with…</h1>

        <Logo /> 

       
        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                We optimize results

</h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                Empathy and Reasoning
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                With an approach that examines complex problems from a human perspective. We evaluate what's working and what's not.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                Creativity and Ideation
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                To explore, visualize and validate evidence-based human-centred design and strategies.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                Analysis and Critique 
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                To leverage research and define a problem space to identify needs. We recognize the importance of adapting to changing contexts.                 </p>
              </div>
            </div>
          </div>
        </section>
  
      </main>
      <Footer />
    </>
  );
}
