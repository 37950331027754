import React from 'react'
import promo from "../media/fluidity.mp4";



function FuidityVideo() {
    return (
        <>
            {/*<span id="blackOverlay" className="w-full h-full"></span> */}
    
          <video className="h-75 w-full  absolute opacity-75 bg-black rounded-lg w-[70%] rounded-lg h-[50%] bg-gray-100 shadow-md p-0 overflow-hidden"   autoPlay muted loop >
          <source src={promo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> 
        </>
         )
    }

export default FuidityVideo