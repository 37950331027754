import React from 'react'
import promo2 from "../assets/img/ln1.png";
import promo3 from "../assets/img/ln2.png";
import promo4 from "../assets/img/ln3.png";
import promo5 from "../assets/img/ln4.png";
import promo6 from "../assets/img/ln5.png";
import promo1 from "../assets/img/ln6.png";
import promo7 from "../assets/img/ln7.png";
import promo8 from "../assets/img/ln8.png";
import promo9 from "../assets/img/ln9.png";



function Logo() {
  return (
    <>
        <div class="container mx-auto py-9 md:py-12 px-4 md:px-6">

{/*         <h2 className="w-full px-10 justify-items-center text-balance">Our Partners</h2><br/><br/>
 */}
            <div class="flex items-strech justify-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8">
                <div class="flex flex-col md:flex-row items-strech justify-between bg-gray-50 dark:bg-gray-800 py-6 px-6 md:py-12 lg:px-12 md:w-8/12 lg:w-7/12 xl:w-8/12 2xl:w-9/12">
                <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                <img src={promo4} alt="" class="" />

                    </div>
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo2} alt="" class="" />
                    </div>
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo5} alt="" class="" />
                    </div>
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo6} alt="" class="" />
                    </div>    
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo3} alt="" class="" />
                    </div>   
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo7} alt="" class="" />
                    </div>      
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo8} alt="" class="" />
                    </div>       
                    <div class="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
                        <img src={promo9} alt="" class="" />
                    </div>      
                </div>
            
            </div><br/><br/><br/>
        </div>
    </>
  )
}

export default Logo