import React from 'react'
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import FuidityVideo from "../components/FuidityVideo.js";
import fun from "../assets/img/longline.png";
import Carousel from './Partners';
import Logo from "../components/Logo.js";

const partnerItems = [
  { src: '../assets/img/longline.png', alt: 'Partner 1' },
  { src: '../assets/img/wood.jpg', alt: 'Partner 2' },
  { src: '../assets/img/sky.jpeg', alt: 'Partner 3' },
  // Add more items as needed
];


function Fluidity(props) {
  return (
    <>
      <Navbar transparent /><br/><br/><br/><br/><br/>
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "75vh"
            }}>        
            <FuidityVideo />
            </div>
            </main>
            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-company text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                D-SIDES Fluidity Matrix™
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                

Design is underpinned by the philosophy and process of human-centred design.

DSIDES Fluidity Matrix™ is a creative and evidence-based approach to solving problems through
which the perspectives of the people you serve, including your customers, users, and partners,
are central to understanding current experiences and reimagining future experiences. It can be
applied across sectors and contexts.
               </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                We intelligently blend evaluation, qualitative and quantitative research with collaborative
methods, such as co-design, to help you connect with your audience and staff in new ways.
DSIDES Fluidity Matrix™ designs innovative, world-class products, services and experiences that
support your success and reinforce your organization’s vision and purpose. We also assist in
implementing and evaluating designs and embedding a culture of continuous improvement that
draws on your clients and your organization inputs to deliver powerful insights. 
                </p>
               {/*  <a href="/contact"><button
                className={
                  (props.transparent
                    ? "bg-white text-gray-800 active:bg-blue-100"
                    : "bg-pink-500 text-white active:bg-blue-600") +
                  " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                }
                type="button"
                style={{ transition: "all .15s ease" }}
              >
<i class='fas fa-phone-alt'></i>  
&nbsp; Contact Us   
              </button></a> */}
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    // src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    src={fun}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-pink-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                    Creativity and Ideation in Consulting
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                    Our workplaces are in a constant state of fluidity. As leaders in People intelligence, we use data and evidence to improve organizational decision-making on People issues across the organization. 
                    </p>
                  </blockquote>
                </div>
              </div>

            </div>
            <h1 className="text-center text-2xl font-bold my-8">We have worked with…</h1>
            <Logo />
        <Footer />

    </>
  )
}

export default Fluidity